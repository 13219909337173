import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { Subject, combineLatest } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { b2cPolicies } from 'src/app/b2c-config';
import { AppSettings } from 'src/app/core/models/app-settings.model';
import { RouteConfig } from 'src/app/core/models/route-config.model';
import { User } from 'src/app/core/models/user.model';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { FeatureFlagService } from 'src/app/core/services/feature-flag.service';
import { PortalCustomerConfigurationService } from 'src/app/core/services/portal-customer-configuration.service';
import { DeliveryRequestCartService } from 'src/app/delivery-requests/delivery-request-cart/delivery-request-cart.service';
import { ReportId } from 'src/app/shared/model/report-id.enum';
import { environment } from 'src/environments/environment';
import { LayoutService } from '../layout.service';
import { ThemeService, themes } from './../../core/services/theme.service';
import { UserService } from './../../core/services/user.service';

const enum B2CPolicyRequest {
  EditProfile,
  ChangePassword
}
@Component({
  selector: 'prt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {

  routeConfigs: RouteConfig[] = [];
  $destroying = new Subject<void>();
  mobileMenuOpen = false;
  userMenuItems!: MenuItem[];
  defaultMenuItems: MenuItem[] = [
    {
      label: 'Sign out',
      command: () => this.signOut()
    },
  ];
  userProfile$ = this.userService.userProfile$;
  featureFlag$ = this.featureFlagService.featureFlag$;
  portalCustomerConfiguration$ = this.portalCustomerConfigurationService.customerConfiguration$;
  isMaintenanceMode: boolean = false;
  showMaintenanceBanner: boolean = false;
  maintenanceBannerMessage: string = '';
  constructor(
    private authService: AuthorizationService,
    private portalCustomerConfigurationService: PortalCustomerConfigurationService,
    private featureFlagService: FeatureFlagService,
    private router: Router,
    private userService: UserService,
    private themeService: ThemeService,
    private deliveryRequestCartService: DeliveryRequestCartService,
    public layoutService: LayoutService) { }
  ngOnInit(): void {
    this.loadUserMenuItems();
    this.deliveryRequestCartService.initService();
    this.userService.userProfile$
      .pipe(
        filter(user => !!user),
        switchMap(() => this.featureFlagService.featureFlag$),
        takeUntil(this.$destroying)
      ).subscribe((settings: AppSettings) => {
        this.applyFeatureFlagSettings(settings);
      });
  }
  private applyFeatureFlagSettings(settings: AppSettings) {
    this.isMaintenanceMode = settings?.isMaintenanceMode;
    this.showMaintenanceBanner = settings?.showMaintenanceBanner;
    this.maintenanceBannerMessage = settings?.maintenanceBannerMessage;
  }

  executePolicy(policy: B2CPolicyRequest) {
    const authority = policy === B2CPolicyRequest.EditProfile ? b2cPolicies.authorities.editProfile.authority :
      b2cPolicies.authorities.forgotPassword.authority;

    let policyFlowRequest = {
      scopes: ["openid"],
      authority: authority,
    };

    this.userService.executeFlowRequest(policyFlowRequest);
  }

  loadUserMenuItems(): void {
    this.userMenuItems = [];
    this.userMenuItems = this.defaultMenuItems;
    combineLatest([
      this.userService.userProfile$,
      this.portalCustomerConfigurationService.customerConfiguration$
    ]).pipe(
      takeUntil(this.$destroying)
    ).subscribe(([user, customerConfiguration]) => {
      this.routeConfigs = [];
      let routeConfigs: RouteConfig[] = [];
      if (customerConfiguration?.isDashboardEnabled) {
        routeConfigs.push(new RouteConfig('Dashboard', 'dashboard', 0));
      }
      if (user?.isShippingCustomer) {
        routeConfigs.push.apply(routeConfigs, [
          new RouteConfig('Vessel Calls', 'vessel-calls', 1),
          new RouteConfig('Port Cost Template (PCT)', 'pct', 103, false, 'max-w-[122px]'),
          new RouteConfig('Reports', 'report-list', 201),
          new RouteConfig('Documents', 'documents', 401)
        ]);
      }

      if (customerConfiguration?.isPortLineupEnabled) {
        routeConfigs.push(new RouteConfig('Port Lineup', 'port-line-up', 501));
      }

      if (user?.isLogisticsCustomer) {
        if (customerConfiguration?.isPurchaseOrderVisible) {
          routeConfigs.push(new RouteConfig('POs', 'purchase-orders', 11));
        }
        if (customerConfiguration?.isShipmentVisible) {
          routeConfigs.push(new RouteConfig('Shipments', 'shipments', 13));
        }
        if (customerConfiguration?.canRequestDelivery) {
          routeConfigs.push(new RouteConfig('Delivery Requests', 'delivery-requests', 15));
        }
        if (customerConfiguration?.reports?.[ReportId.StockReport]) {
          routeConfigs.push(new RouteConfig('Stock Report', `report/${ReportId.StockReport}`, 202));
        }
        if (customerConfiguration?.reports?.[ReportId.ContractLogisticsReport]) {
          routeConfigs.push(new RouteConfig('CL Report', `report/${ReportId.ContractLogisticsReport}`, 210));
        }
        if (customerConfiguration?.isKpiVisible) {
          routeConfigs.push(new RouteConfig('KPI Reports', environment.kpiReportReportUrl, 203, true));
        }
        if (customerConfiguration?.reports?.[ReportId.SustainabilityReport]) {
          routeConfigs.push(new RouteConfig('Sustainability Report', `report/${ReportId.SustainabilityReport}`, 204, false));
        }
      };

      if (customerConfiguration?.reports?.[ReportId.FinancialDashboard]) {
        routeConfigs.push(new RouteConfig('Financial Dashboard', `report/${ReportId.FinancialDashboard}`, 250, false, 'max-w-[92px]'));
      }

      if (customerConfiguration?.isHotPortNewsVisible) {
        routeConfigs.push(new RouteConfig('Hot Port News', 'hot-port-news', 300, false, 'min-w-[73px]'));
      }
      this.routeConfigs = routeConfigs.sort(function (a, b) { return a.order - b.order; });
      this.userMenuItems = [...this.getUserMenuItemsConfig(user), ...this.defaultMenuItems];
    });
  }

  getUserMenuItemsConfig(user: User | null): any {
    return [
      {
        label: 'Admin',
        visible: user?.isAdmin,
        command: () => { this.router.navigate(['admin']); }
      },
      {
        label: 'Light Theme',
        visible: this.themeService.currentTheme !== themes.light,
        command: () => { this.themeService.setLightTheme(); this.loadUserMenuItems(); }
      },
      {
        label: 'Dark Theme',
        visible: this.themeService.currentTheme !== themes.dark,
        command: () => { this.themeService.setDarkTheme(); this.loadUserMenuItems(); }
      },
      // {
      //   label: 'Reset Password',
      //   command: () => this.executePolicy(B2CPolicyRequest.ChangePassword)
      // },
    ];
  }

  closeMobileMenu() {
    setTimeout(() => {
      this.mobileMenuOpen = false;
    }, 0);
  }


  signOut(): void {
    this.authService.signOut();
  };

  ngOnDestroy(): void {
    this.$destroying.next(undefined);
    this.$destroying.complete();
  }
}
