import { ComponentRef, ElementRef, Injectable, Type } from '@angular/core';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { ISidebarComponent } from './sidebar/sidebar-component.interface';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  showMapViewSwitcherSubject = new BehaviorSubject<boolean>(false);
  showMapViewSwitcher$ = this.showMapViewSwitcherSubject.asObservable();
  private showMapSubject$ = new ReplaySubject<boolean>(1);
  showMap$ = this.showMapSubject$.asObservable();
  private rootScrollingSubject$ = new ReplaySubject<ElementRef>(1);
  rootScrolling$ = this.rootScrollingSubject$.asObservable();
  private ref?: ElementRef;

  private showDeliveryCartIconSubject = new BehaviorSubject<boolean>(false);
  showDeliveryCartIcon$ = this.showDeliveryCartIconSubject.asObservable();
  private activeSidebarComponentSubject = new BehaviorSubject<Type<ISidebarComponent> | null>(null);
  activeSidebarComponent$ = this.activeSidebarComponentSubject.asObservable();
  activeSidebarComponentRef: ComponentRef<ISidebarComponent> | null = null;

  constructor() {
    combineLatest([this.showMapViewSwitcher$, this.showMap$]).subscribe(([showMapViewSwitcher, showMap]) => {
      this.disableRootScroll(showMapViewSwitcher && showMap);
    });
  }

  getCurrentSidebarComponent() {
    return this.activeSidebarComponentSubject.value;
  }

  toggleMapViewSwitcher(show = true, value?: boolean) {
    if (value !== undefined) {
      this.showMapSubject$.next(!!value);
    }

    this.showMapViewSwitcherSubject.next(show);
  }

  disableRootScroll(disable: boolean = true) {
    if (disable) {
      if (this.ref?.nativeElement.classList?.contains?.('overflow-y-scroll')) {
        this.ref?.nativeElement.classList.remove('overflow-y-scroll');
      }
    }
    else {
      if (!this.ref?.nativeElement.classList?.contains?.('overflow-y-scroll')) {
        this.ref?.nativeElement.classList.add('overflow-y-scroll');
      }
    }
  }

  setRootScrollingReference(ref: ElementRef) {
    this.rootScrollingSubject$.next(ref);
    this.ref = ref;
  }

  showSidebarComponent(component: any) {
    this.activeSidebarComponentSubject.next(component);
  }

  setSidebarComponentReference(ref: ComponentRef<ISidebarComponent> | null) {
    this.activeSidebarComponentRef = ref;
  }

  hideSidebarComponent() {
    this.activeSidebarComponentSubject.next(null);
  }

  showDeliveryCartIcon() {
    this.showDeliveryCartIconSubject.next(true);
  }
}
